import React, { useContext } from "react";
import PropTypes from "prop-types";
import Card from "./card.js";
import Link from "gatsby-link";
import adicionar from "../../images/adicionar.png";
import { AuthContext } from "../../context/GlobalContextProvider";
export default function PredioComponent({ buildings }) {
  const context = useContext(AuthContext);
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12 my-12 self-center">
        {buildings?.map((building) => (
          <Card building={building} key={building?.id} />
        ))}
        {context?.user && (
          <Link to="/predios/novoPredio">
            <img className="img-zoom" src={adicionar} />
          </Link>
        )}
      </div>
    </div>
  );
}
PredioComponent.propTypes = {
  buildings: PropTypes.any,
};
