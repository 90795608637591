import React, { useEffect, useState, useContext } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PredioComponent from "./generics/predios.js";
import api from "../api";
import { AuthContext } from "../context/GlobalContextProvider";
import { Button } from "antd";
import { navigate } from "gatsby";

function IndexPage() {
  useEffect(() => {
    getData();
  }, []);
  const context = useContext(AuthContext);
  const [data, setData] = useState();
  const [carrossel, setCarrossel] = useState([]);
  const getData = async () => {
    const response = await api.get("/predios?apareceNoInicio=true");
    const page = await api.get("/inicio");
    setData(response.data);
    setCarrossel(page.data.predios);
  };
  return (
    <Layout aboveFooter carousel={carrossel} loading={!data}>
      {context?.user && (
        <Button
          className="mb-10"
          style={{ borderColor: "orange", color: "orange" }}
          danger
          onClick={() => navigate(`/edicao/inicio`)}
        >
          Editar
        </Button>
      )}
      <React.Fragment>
        <SEO
          keywords={[`Bivver`, `Construção`]}
          description="Empreendimentos"
          title="Bivver"
        />
        <center>
          <h1>Nossos empreendimentos</h1>
        </center>
        <br />
        <div>
          <PredioComponent buildings={data} />
        </div>
      </React.Fragment>
    </Layout>
  );
}

export default IndexPage;
